import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FileVersion from './FileVersion';

class VersionsList extends Component {
    renderVersion = version =>
        (<FileVersion
            store={this.props.store}
            version={version}
            key={`${version.versionId}-${version.lastModified}-${version.size}`}
        />);

    render () {
        return (
            <div className='file-versions'>
                {
                    this.props.store.file.versions.length > 0 &&
                    this.props.store.file.versions.map(this.renderVersion)
                }
            </div>
        );
    }
}

export default observer(VersionsList);

VersionsList.propTypes = {
    store: PropTypes.object.isRequired
};
