import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { MenuItem } from '@mui/material';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import { buildAction } from '../../../../base/action';
import { FileTypes } from '../../../../lib';
import { pubsub } from '../../../../base';
import storage from '../../../storage';
import splitContextActions from '../../../../base/toolbar-context-actions';
import ToolbarContextActions, { renderListAction } from '../../../../Components/ToolbarContextActions';
import withResponsiveListeners from '../../../../utils/Responsive';

import { TABLES } from './utils';
import Toolbar from '../../../Toolbar';
import GlobalTools from '../../../GlobalTools';

import { ShowHistoryContextAction, ShowCommentsContextAction } from '../../../ContextActions';
import Trigger from './Filters/Trigger';

const S = {
    DesktopTableButton: styled.div`
        width: 54px;
        height: 54px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        border-radius: 10px;
        font-weight: bold;
        cursor: pointer;

        ${({ active }) => active && css`
            background-color: var(--grey4);
        `}
    `,
    TableIcon: styled.div`
        font-size: 24px;
    `,
    DesktopTableTitle: styled.div`
        font-size: 10px;
    `,
    MobileTableButton: styled.div`
        display: flex;
        align-items: center;
        column-gap: 30px;
        color: var(--black);
    `,
    MobileTableTrigger: styled(Trigger)`
        min-width: 180px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        color: var(--black);
    `,
    MobileTableTitle: styled.div`
        font-size: 14px;
    `
};

const openSettingsAction = buildAction({
    action: function () {
        pubsub.publish('fv.ccad.settings.dialog.open');
        return Promise.resolve(null);
    },
    properties: {
        multiple: false,
        isAllowed (asset) {
            return asset.exists &&
                asset.fileType.type === FileTypes.connect_cad;
        },
        title: gettext('Settings'),
        icon: 'icon-settings',
        GAAction: 'CCAD_Settings',
        GALabel: 'Open_CCAD_Settings'
    }
}, 'openSettings', 'connect-cad-file');

const DesktopTable = ({ table, activeTable }) => {
    const onClick = () => pubsub.publish('fv.ccad.table.changed', {
        table: table.name,
        previousTable: activeTable,
        addToHistory: true
    });

    return (
        <S.DesktopTableButton
            onClick={onClick}
            title={table.title}
            active={activeTable === table.name}
            data-what='fv-action'
            data-which={`table-${table.name}`}
            ga-action={`CCAD_${table.name.toUpperCase()}`}
            ga-label={`Open_CCAD_${table.name.toUpperCase()}`}
        >
            <S.TableIcon className={`icon-${table.icon}`} />
            <S.DesktopTableTitle>{ table.title }</S.DesktopTableTitle>
        </S.DesktopTableButton>
    );
};

DesktopTable.propTypes = {
    table: PropTypes.object,
    activeTable: PropTypes.string
};

const MobileTable = ({ table, activeTable, popperState }) => {
    const handleClick = () => {
        pubsub.publish('fv.ccad.table.changed', {
            table: table.name,
            previousTable: activeTable,
            addToHistory: true
        });
        popperState.close();
    };

    return (
        <MenuItem
            onClick={handleClick}
            value={table.name}
            key={`mb-table-btn-${table.name}`}
            data-what='ccad-table-button'
            data-which={table.name}
            style={{ minWidth: '180px' }}
        >
            <S.MobileTableButton>
                <S.TableIcon className={`icon-${table.icon}`} />
                <S.MobileTableTitle>{ table.title }</S.MobileTableTitle>
            </S.MobileTableButton>
        </MenuItem>
    );
};

MobileTable.propTypes = {
    table: PropTypes.object,
    activeTable: PropTypes.string,
    popperState: PropTypes.object
};

const BackArrow = ({ isMobile }) => {
    const goBack = () => {
        pubsub.publish('fv.ccad.back');
    };

    return (
        <a
            className='tool icon-arrow-back-16'
            data-what='fv-action'
            data-which='ccad-back'
            style={{
                margin: isMobile ? '0 0 0 10px' : '',
                fontSize: '16px'
            }}
            onClick={goBack}
        />
    );
};

BackArrow.propTypes = {
    isMobile: PropTypes.bool
};

const ConnectCADToolbar = observer(({ store, screen }) => {
    const fileStore = store.file;
    const { baseName: fileName, actions } = fileStore.file;
    const [table, setTable] = React.useState(
        storage.get('ccad.table', TABLES.circuits.name)
    );
    const isMobile = React.useMemo(() => !['md', 'lg'].includes(screen.size), [screen]);
    const mobileTablePopper = usePopupState({ variant: 'popper', popupId: 'mobileTableMenu' });

    React.useEffect(() => {
        pubsub.subscribe(ConnectCADToolbar, 'fv.ccad.table.changed', changeTable);

        return () => {
            pubsub.unsubscribeAll(ConnectCADToolbar);
        };
    }, []);

    const doAction = (action, context = null) => () => {
        return actions[action].action.call(context, fileStore.file);
    };

    const changeTable = ({ table }) => {
        setTable(table);
    };

    return (
        <div className='toolbar fv-toolbar ccad-fv-toolbar'>
            {
                isMobile
                    ? <div
                        className='dropdown context-tools ccad-toolbar-left-panel'
                        data-what='toolbar-dropdown'
                    >
                        <BackArrow isMobile={isMobile} />
                        <a className='dropdown-toggle' data-toggle='dropdown'>
                            <span className='tool icon-more'/>
                        </a>

                        <ul className='dropdown-menu override-bootstrap-dropdown'>
                            {
                                splitContextActions(actions, 3, ['view'])
                                    .primary
                                    .map(action => renderListAction(doAction, action))
                            }
                            <ShowHistoryContextAction store={store} />
                            <ShowCommentsContextAction store={store} />
                        </ul>
                    </div>
                    : <Toolbar.FileName>
                        <BackArrow isMobile={isMobile} />
                        {`${fileName}: ${TABLES[table].title}`}
                    </Toolbar.FileName>
            }
            <div className='ccad-toolbar-middle-panel'>
                {
                    isMobile
                        ? <React.Fragment>
                            <S.MobileTableTrigger
                                {...bindTrigger(mobileTablePopper)}
                                data-what='ccad-table-button'
                                data-which='mobile-table-menu'
                            >
                                <S.TableIcon className={`icon-${TABLES[table].icon}`} />
                                <S.MobileTableTitle>{ TABLES[table].title }</S.MobileTableTitle>
                            </S.MobileTableTrigger>
                            <Menu {...bindMenu(mobileTablePopper)}>
                                {
                                    Object.values(TABLES).map(t =>
                                        <MobileTable
                                            table={t}
                                            activeTable={table}
                                            popperState={mobileTablePopper}
                                            key={`mb-table-${t.name}`}
                                        />
                                    )
                                }
                            </Menu>
                        </React.Fragment>
                        : Object.keys(TABLES).map(t =>
                            <DesktopTable
                                key={t}
                                table={TABLES[t]}
                                activeTable={table}
                            />
                        )
                }
            </div>

            <div className='ccad-toolbar-right-panel'>
                {
                    !isMobile &&
                        <React.Fragment>
                            <ToolbarContextActions
                                items={[fileStore.file]}
                                actions={{ openSettingsAction, ...actions }}
                                hiddenActions={['view', 'restore', 'deleteVersion']}
                            />
                            <Toolbar.ToolSeparator />
                            <GlobalTools store={store} />
                        </React.Fragment>
                }
            </div>
        </div>
    );
});

ConnectCADToolbar.propTypes = {
    store: PropTypes.object
};

export default withResponsiveListeners(ConnectCADToolbar);
