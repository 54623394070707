import React from 'react';

import Version1 from './Viewers/ConnectCADViewer/v1/Toolbar';
import Version2 from './Viewers/ConnectCADViewer/v2/Toolbar';

const ConnectCADToolbar = props => Settings.NEW_FEATURES.isActive('use-connect-cad-version-2')
    ? <Version2 {...props} />
    : <Version1 {...props} />;

export default ConnectCADToolbar;
