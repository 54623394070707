import React from 'react';

import { observer } from 'mobx-react';
import { computed } from 'mobx';

import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

import Icon from '../../Components/Icon';
import { actions as assetActions } from '../../asset/actions';

const UnsupportedVGXVersion = observer(({ store }) => {
    const vwx = computed(
        () => store.file.file.relatedFiles().find(file => file.fileType.type === 'vwx')
    ).get();
    const generate3D = () => assetActions.generate3DModel.action.call(null, vwx);

    return (
        <div className='no-preview'>
            <div className='message-box flex-col'>
                <Icon icon='icon-missing-3d-capabilities'/>
                <div className='message'>
                    <span className='msg-row-prim'>{ gettext('3D model version not supported') }</span>
                    <span>{ gettext('The version of exported 3D model is no longer supported for viewing online. Re-generate the model to be able to view it.') }</span>
                </div>

                <br/><br/>

                {
                    vwx && vwx.ownerInfo.owner === window.Settings.user.login &&
                    <Button onClick={generate3D} variant='primary'>{ gettext('Re-generate') }</Button>
                }
            </div>
        </div>
    );
});

export default UnsupportedVGXVersion;
