import React from 'react';

import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FileTypes } from '../lib';

const ShowHistory = observer(({ store }) => {
    const toggleHistory = () => {
        runInAction(() => {
            store.ui.history = !store.ui.history;
        });
    };

    return (
        <a
            className={`tool icon-versions left-on-small ${store.ui.history ? 'tool-active' : ''}`}
            title={ store.ui.history ? gettext('Hide history') : gettext('Show history') }
            onClick={toggleHistory}
            data-what='fv-action'
            data-which='toggle-history'
            ga-action='File_View'
            ga-label='Toggle_Versions'
        />
    );
});

ShowHistory.propTypes = {
    store: PropTypes.object
};

const ShowComments = observer(({ store }) => {
    const toggleComments = () => {
        runInAction(() => {
            store.ui.comments = !store.ui.comments;
        });
    };

    return (
        <a
            className={`tool icon-comments ${store.ui.comments ? 'tool-active' : ''}`}
            title={ store.ui.comments ? gettext('Hide comments') : gettext('Show comments') }
            onClick={toggleComments}
            data-what='fv-action'
            data-which='toggle-comments'
            ga-action='Comments'
            ga-label='Toggle_Comments'
        />
    );
});

ShowComments.propTypes = {
    store: PropTypes.object
};

const CloseFile = observer(({ store }) => {
    const close = () => {
        store.ui.hide();
        window.FilePreview.close();
    };

    return store.ui.options.closeEnabled &&
        <a
            className='tool icon-close'
            title={ gettext('Close') }
            onClick={close}
            data-what='fv-action'
            data-which='close'
            ga-action='File_View'
            ga-label='Close_FileView'
        />;
});

CloseFile.propTypes = {
    store: PropTypes.object
};

const GlobalTools = props =>
    <div className='gl-tools'>
        <ShowHistory {...props} />
        { props.store.file.file.fileType.type !== FileTypes.vcdoc && <ShowComments {...props} />}
        <CloseFile {...props} />
    </div>;

GlobalTools.propTypes = {
    store: PropTypes.object
};

export default observer(GlobalTools);
export {
    ShowHistory,
    ShowComments,
    CloseFile
};
