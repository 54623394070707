import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Controller } from './Default';
import FullScreen from './Widgets/FullScreen';
import ImageZoom from './Widgets/ImageZoom';
import Navigation from './Widgets/Navigation';

const ImageController = observer(({ store, viewer }) =>
    <Controller store={store}>
        <div className='control-section left'>
            <ImageZoom viewer={viewer}/>
        </div>

        <div className='control-section center'>
            <Navigation store={store}/>
        </div>

        <div className='control-section right'>
            <FullScreen store={store}/>
        </div>
    </Controller>
);

ImageController.propTypes = {
    store: PropTypes.object,
    viewer: PropTypes.object
};

export default ImageController;
