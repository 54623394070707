import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { computed, makeObservable } from 'mobx';

import splitContextActions from '../base/toolbar-context-actions';

const renderListAction = (doAction, action) =>
    (<li
        onClick={doAction(action.name)}
        title={action.data.properties.title}
        key={action.name}
        data-what='fv-action'
        data-which={action.name}
        ga-action='File_View_Toolbar'
        ga-label={action.data.properties.GALabel}
    >
        <div className='dropdown-item'>
            <span className={`icon ${action.data.properties.icon}`}/>
            <span>{ action.data.properties.title }</span>
        </div>
    </li>);

class ToolbarContextActions extends Component {
    state = {
        visibleActionsCount: $(window).width() < 360 ? 3 : 4
    };

    constructor (props) {
        super(props);

        makeObservable(this, {
            toolbarContextActions: computed
        });
    }

    get toolbarContextActions () {
        return splitContextActions(this.props.actions, this.state.visibleActionsCount, this.props.hiddenActions);
    }

    doAction = (action, context = null) =>
        () => {
            return this.props.actions[action].action.call(context, this.props.items);
        };

    renderAction = action => {
        const properties = action.data.properties;
        const testId = 'ctx_toolbar__' + properties.title.toLowerCase().replace(/ /g, '-');

        return (
            <a
                className={`tool ${properties.icon}`}
                onClick={this.doAction(action.name)}
                title={properties.title}
                key={action.name}
                data-what='fv-action'
                data-which={action.name}
                ga-action='File_View_Toolbar'
                ga-label={properties.GALabel}
                data-test-id={testId}
            >
                {
                    properties.pingAnimator &&
                    properties.pingAnimator(this.props.items) &&
                    <span className='ping-animator active' />
                }
            </a>
        );
    };

    renderSecondaryActions = () =>
        (<div className='dropdown' data-what='toolbar-dropdown'>
            <a
                className='dropdown-toggle'
                data-toggle='dropdown'
            >
                <span className='tool icon-more'/>
            </a>

            <ul className='dropdown-menu override-bootstrap-dropdown'>
                { this.toolbarContextActions.secondary.map(action => renderListAction(this.doAction, action)) }
            </ul>
        </div>);

    render () {
        return (
            <div className='context-tools'>
                <div className='context-tools-primary'>
                    { this.toolbarContextActions.primary && this.toolbarContextActions.primary.map(this.renderAction) }
                </div>
                { this.toolbarContextActions.secondary.length > 0 && this.renderSecondaryActions() }
            </div>
        );
    }
};

ToolbarContextActions.propTypes = {
    actions: PropTypes.objectOf(PropTypes.object).isRequired,
    hiddenActions: PropTypes.array,
    items: PropTypes.array
};

export default observer(ToolbarContextActions);
export { renderListAction };
