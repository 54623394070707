import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { FileTypes } from '../lib/file';
import '../view/action-toolbar.vm';

import renderViewer from './Viewers';
import Comments from './Sections/Comments';
import History from './Sections/History';
import BrandingLogo from '../branding/BrandingLogo';
import Toolbar from './Toolbar';
import ConnectCADToolbar from './ConnectCADToolbar';

const FVToolbar = props => {
    return props.viewerType === FileTypes.connect_cad
        ? <ConnectCADToolbar store={props.store} />
        : <Toolbar store={props.store} />;
};

FVToolbar.propTypes = {
    viewerType: PropTypes.string,
    store: PropTypes.object
};

class FileViewInner extends Component {
    constructor (props) {
        super(props);

        makeObservable(this, {
            branding: computed,
            viewerType: computed
        });
    }

    componentDidCatch (error, errorInfo) {
        window.Sentry.captureException(error, { extra: errorInfo }); // eslint-disable-line
    }

    get branding () {
        const file = this.props.store.file.file;
        const fileType = this.props.store.file.file.fileType.type;
        return [FileTypes.connect_cad, FileTypes.vcdoc].includes(fileType)
            ? false
            : !file.isEmpty && file.ownerInfo.branding;
    }

    get viewerType () {
        if (this.props.store.file.preview.error) return 'default';

        const file = this.props.store.file.file;
        if (file.isEmpty && !file.name) return 'empty';
        if (!file.isAvailable()) return 'default';

        return /jpe?g$/i.test(file.prefix) && file.fileType.type === FileTypes.image
            ? FileTypes.panorama
            : (file.fileType.type || 'default');
    }

    render () {
        return (
            <div className='file-view'>
                <FVToolbar
                    store={this.props.store}
                    viewerType={this.viewerType}
                />

                <History store={this.props.store}/>
                {
                    this.props.store.file.file.fileType.type !== FileTypes.vcdoc &&
                    <Comments store={this.props.store}/>
                }

                <div className='viewer-wrapper'>
                    {this.branding && <BrandingLogo branding={ this.branding } />}
                    { renderViewer({ store: this.props.store, type: this.viewerType }) }
                </div>
            </div>
        );
    }
}

FileViewInner.propTypes = {
    store: PropTypes.object
};

export default observer(FileViewInner);
