import FileStore from './file-store';
import UIStore from './ui-store';

import { action, observable, makeObservable } from 'mobx';

class AppStore {
    loading = false;
    isPortalFV = true;

    startLoading = () => {
        this.loading = true;
    };

    endLoading = () => {
        this.loading = false;
    };

    constructor () {
        makeObservable(this, {
            loading: observable,
            startLoading: action,
            endLoading: action
        });

        this.ui = new UIStore(this);
        this.file = new FileStore(this);
    };
};

export default AppStore;
