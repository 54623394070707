import { buildAction } from '../base/action';
import { Dialog } from '../base/dialog';
import { isLinkView } from '../preview/link';
import { getVRLocalStorageKey, vrMoreOnMobile } from './vr-code.vm';

const openDialog = (fileType, fileStore) => () => {
    return Dialog.open({
        component: 'dialog-vr-code',
        ctx: {
            ctx: {
                fileType,
                file: fileStore,
                url: window.location.href
            }
        }
    }).result;
};

const vrCodeAction = (fileType, fileStore) => {
    const action = openDialog(fileType, fileStore);

    return buildAction({
        action,
        properties: {
            multiple: false,
            isAllowed () {
                return isLinkView();
            },
            title: gettext('VR code'),
            icon: 'icon-vr-code',
            autoExecute: () => {
                !(window.localStorage.getItem(getVRLocalStorageKey()) === 'true') &&
                vrMoreOnMobile(fileType) &&
                action();
            },
            pingAnimator: () => vrMoreOnMobile(fileType),
            GALabel: 'Click_VRCode'
        }
    }, 'vrCode', 'asset');
};

export { vrCodeAction };
