import React from 'react';

import { computed, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { fileResourceId } from '../file';
import { commonPropTypes } from './utils';

class VWXViewer extends React.Component {
    constructor (props) {
        super(props);

        makeObservable(this, {
            relatedTypes: computed
        });

        this.reactionDisposer = reaction(
            () => this.props.store.file.file,
            () => this.props.store.endLoading(),
            {
                delay: 500
            }
        );
    }

    get relatedTypes () {
        return {
            pdf: this.props.store.file.file.relatedFiles().filter(file => file.fileType.type === 'pdf'),
            vgx: this.props.store.file.file.relatedFiles().filter(file => file.fileType.type === 'vgx')
        };
    };

    componentDidMount () {
        this.props.store.endLoading();
    };

    componentWillUnmount () {
        this.reactionDisposer && this.reactionDisposer();
    }

    download = () =>
        this.props.store.file.file.actions.download.action(this.props.store.file.file);

    generate3D = () =>
        this.props.store.file.file.actions.generate3DModel.action.call(null, this.props.store.file.file);

    exportPdf = () =>
        this.props.store.file.file.actions.exportPdf.action.call(null, this.props.store.file.file);

    renderDownloadButton = () => {
        return this.props.store.file.file.actions.download &&
        !this.props.store.file.isOwner &&
            (<div className='fv-list-item'>
                <div className='fv-thumb'>
                    <span className='icon icon-download'/>
                </div>
                <span className='item-name'>
                    <a onClick={this.download}>
                        { gettext('Download') }
                    </a>
                </span>
            </div>);
    };

    openFile = item => {
        this.props.store.file.fileResource = fileResourceId.fromAsset(item);
        this.props.store.file.file = item;
        window.history.replaceState(null, item.name, this.props.store.file.fileResource.asUrl());
    };

    renderGeneratedPDF = item =>
        (<div
            className='fv-list-item'
            key={item.name}
        >
            <div className='fv-thumb'>
                { item.thumbnail
                    ? <img className='fv-thumb-image' src={item.thumbnail }/>
                    : <span
                        className='svg-file-icon fv-default-icon'
                        dangerouslySetInnerHTML={{ __html: item.defaultFileTypeIcon }}
                    />}
            </div>
            <span className='item-name'>
                {`${gettext('PDF')} - `}
                <a
                    onClick={this.openFile.bind(this, item)} // eslint-disable-line
                    ga-action='File_View'
                    ga-label='Open_Related_File'
                    data-what='related-file'
                    data-which={item.name}
                >{ item.name }
                </a>
            </span>
        </div>);

    renderGenerated3D = item => {
        return (
            <div
                className='fv-list-item'
                key={item.name}
            >
                <div className='fv-thumb'>
                    { item.thumbnail
                        ? <img className='fv-thumb-image' src={item.thumbnail }/>
                        : <span
                            className='svg-file-icon fv-default-icon'
                            dangerouslySetInnerHTML={{ __html: item.defaultFileTypeIcon }}
                        />}
                </div>

                <span className='item-name'>
                    {`${gettext('3D Model')} - `}
                    <a
                    onClick={this.openFile.bind(this, item)} // eslint-disable-line
                        ga-action='File_View'
                        ga-label='Open_Related_File'
                        data-what='related-file'
                        data-which={item.name}
                    >{ item.name }
                    </a>
                </span>
            </div>
        );
    };

    renderGeneratePDF = () => {
        const fileExists = this.props.store.file.file.exists;

        return (
            <div className='fv-list-item'>
                <div className='fv-thumb'>
                    <span className='fv-default-icon icon-file-pdf'/>
                </div>
                <span className='item-name'>{ gettext('PDF - not generated') }</span>
                {
                    this.props.store.file.isOwner &&
                this.props.store.ui.options.closeEnabled &&
                fileExists &&
                    <span
                        className='vw-btn-default vw-btn-prim'
                        onClick={this.exportPdf}
                        ga-action='File_View'
                        ga-label='Click_Inner_Export_Pdf'
                        data-what='export-related-pdf'
                    >{ gettext('Generate') }
                    </span>
                }
            </div>
        );
    };

    renderGenerate3D = () => {
        const fileExists = this.props.store.file.file.exists;

        return (
            <div className='fv-list-item'>
                <div className='fv-thumb'>
                    <span className='fv-default-icon icon-file-vgx'/>
                </div>
                <span className='item-name'>{ gettext('3D model - not generated') }</span>
                {
                    this.props.store.file.isOwner &&
                    this.props.store.ui.options.closeEnabled &&
                    fileExists &&
                    <span
                        className='vw-btn-default vw-btn-prim'
                        onClick={this.generate3D}
                        ga-action='File_View'
                        ga-label='Click_Inner_Gnerate_3d'
                        data-what='export-related-3d-model'
                    >{ gettext('Generate') }
                    </span>
                }
            </div>
        );
    };

    render () {
        if (this.props.store.file.loading) {
            setTimeout(() => {
                this.props.store.endLoading();
            }, 1000);
        }

        return (
            <div
                className='fileview-component-loader'
                data-what='file-viewer'
                {...this.props.controllerTogglers}
            >

                { this.props.children }

                <div className='no-preview'>
                    <div className='message-box flex-col'>
                        <div className='prim-column'><span className='icon icon-view'/></div>
                        <div className='message'>

                            <span className='msg-row-prim'>{ gettext('No preview') }</span>

                            <p className='fv-generated-files-msg'>
                                { gettext('Vectorworks files cannot be opened in a browser but you can view the following files generated from it:') }
                            </p>

                            <div className='fv-related-files'>
                                <div className='fv-generated-files'>
                                    { this.relatedTypes.pdf.length > 0 && this.relatedTypes.pdf.map(this.renderGeneratedPDF) }
                                    { this.relatedTypes.vgx.length > 0 && this.relatedTypes.vgx.map(this.renderGenerated3D) }
                                </div>

                                <div className='fv-not-generated-files'>
                                    { this.relatedTypes.pdf.length === 0 && this.renderGeneratePDF() }
                                    { this.relatedTypes.vgx.length === 0 && this.renderGenerate3D() }
                                </div>

                                { !this.props.store.file.isOwner && this.renderDownloadButton() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default observer(VWXViewer);

VWXViewer.propTypes = {
    ...commonPropTypes
};
