import React from 'react';
import PropTypes from 'prop-types';
import { runInAction } from 'mobx';

const ShowHistoryContextAction = ({ store }) => {
    const [title, setTitle] = React.useState(store.ui.history
        ? gettext('Hide history')
        : gettext('Show history')
    );

    const toggleHistory = () => {
        runInAction(() => {
            store.ui.history = !store.ui.history;
            setTitle(store.ui.history
                ? gettext('Hide history')
                : gettext('Show history')
            );
        });
    };

    return (
        <li
            onClick={toggleHistory}
            title={title}
            data-what='fv-action'
            data-which='toggle-history'
            ga-action='File_View'
            ga-label='Toggle_Versions'
        >
            <div className='dropdown-item'>
                <span className='icon icon-versions'/>
                <span>{ title }</span>
            </div>
        </li>
    );
};

ShowHistoryContextAction.propTypes = {
    store: PropTypes.object
};

const ShowCommentsContextAction = ({ store }) => {
    const [title, setTitle] = React.useState(store.ui.comments
        ? gettext('Hide comments')
        : gettext('Show comments')
    );

    const toggleComments = () => {
        runInAction(() => {
            store.ui.comments = !store.ui.comments;
        });
        setTitle(store.ui.comments
            ? gettext('Hide comments')
            : gettext('Show comments')
        );
    };

    return (
        <li
            onClick={toggleComments}
            title={title}
            data-what='fv-action'
            data-which='toggle-comments'
            ga-action='Comments'
            ga-label='Toggle_Comments'
        >
            <div className='dropdown-item'>
                <span className='icon icon-comments'/>
                <span>{ title }</span>
            </div>
        </li>
    );
};

ShowCommentsContextAction.propTypes = {
    store: PropTypes.object
};

export { ShowHistoryContextAction, ShowCommentsContextAction };
