import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import GlobalTools from './GlobalTools';
import ToolbarContextActions from '../Components/ToolbarContextActions';

const Toolbar = ({ store }) =>
    (<div className='toolbar fv-toolbar'>
        <Toolbar.FileName>{store.file.file.name}</Toolbar.FileName>

        <GlobalTools store={store} />

        <Toolbar.ToolSeparator />

        <ToolbarContextActions
            items={[store.file.file]}
            actions={store.file.file.actions}
            hiddenActions={['view', 'restore', 'deleteVersion']}
        />
    </div>);

Toolbar.propTypes = {
    store: PropTypes.object
};

Toolbar.FileName = ({ children }) =>
    <div
        className='toolbar-prim-text file-name visible-lg-block pull-left'
        what='file-name'
    >{ children }
    </div>;

Toolbar.ToolSeparator = () => <span className='tool-separator'/>;

export default observer(Toolbar);
