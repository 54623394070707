import React from 'react';
import PropTypes from 'prop-types';

import pubsub from '../../base/pubsub';
import pushNotifier from '../../base/push-notifier';
import Icon from '../../Components/Icon';
import { StorageProvider } from '../../browser/storage-providers';

const asyncInterval = async (callback, ms, triesLeft = 5) => {
    return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
            if ((await callback())) {
                resolve();
                clearInterval(interval);
            } else if (triesLeft <= 1) {
                reject();
                clearInterval(interval);
            }
            triesLeft--;
        }, ms);
    });
};

const PendingVersion = ({ store }) => {
    React.useEffect(() => {
        const asset = store.file.file;
        if (
            asset.storageProvider.storageType !== StorageProvider.S3.storageType ||
            (Settings.openedFromPublicLink && !window.Settings.user.isAuthenticated)
        ) {
            asyncInterval(
                async () => {
                    try {
                        const data = await store.file.fileResource.load();
                        return asset.data.version_id !== data.asset.version_id;
                    } catch (error) {
                        return false;
                    }
                },
                2500,
                5
            )
                .then(() => store.file.open(store.file.fileResource));
        } else {
            pushNotifier.listen('asset.file', 'v1');
            pubsub.subscribe(PendingVersion, 'push-notification.v1.asset.file', ({ event, data }) => {
                if (event === 'new_version' && asset.data.resource_id === data.asset.resource_id) {
                    store.file.open(store.file.fileResource);
                }
            });
            return () => pubsub.unsubscribeAll(PendingVersion);
        }
    }, []);

    return (
        <div className='no-preview'>
            <div className='message-box flex-col'>
                <Icon icon='icon-partially-uploaded'/>
                <div className='message'>
                    <span className='msg-row-prim'>{ gettext('File version not available') }</span>
                    <span>{ gettext('Sorry, the version you\'re trying to view is not yet uploaded. Please try reloading the page later.') }</span>
                </div>
            </div>
        </div>
    );
};

PendingVersion.propTypes = {
    store: PropTypes.object
};

export default PendingVersion;
