import React from 'react';

import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';
import { detectPanoramaImage } from '~static/file-viewer/panorama/jpeg';
import { Loader } from '~static/file-viewer/panorama/loader';
import ImageController from './Controllers/ImageController';
import ImageViewer from './ImageViewer';
import PanoramaController from './Controllers/PanoramaController';
import PanoramaViewer from './PanoramaViewer';
import { queryString } from '~static/js/lib';
import { qrCodeAction } from '../../share/qr-code-action';
import { FileTypes } from '~static/js/lib/file';

const ImageLoader = React.forwardRef(({ store, children, ...rest }, ref) => {
    const [imageUrl, setImageUrl] = React.useState(null);
    const [sourceUrl, setSourceUrl] = React.useState('');
    const [isPanorama, setIsPanorama] = React.useState('unknown');
    const viewer = React.useRef(null);

    React.useEffect(() => {
        const disposer = autorun(() => store.file.sourceUrl && loadFile());
        return () => disposer();
    }, []);

    const loadFile = () => {
        if (sourceUrl !== store.file.sourceUrl) {
            setSourceUrl(store.file.sourceUrl);
            store.startLoading();

            const imagePromise = Settings.offlinePresentation
                ? Promise.resolve(store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    store.file.sourceUrl,
                    {
                        response_type: 'data',
                        viewable: 'off'
                    }
                ));

            imagePromise.catch(err => {
                if (err?.status === 404) {
                    store.file.setNotFoundFile();
                }
            })
                .then(data => {
                    loadImage(store.file.file.fileType.type === FileTypes.panorama, data.url);
                });
        }
    };

    const loadImage = (isPanorama, imageUrl) => {
        const loader = new Loader();
        loader.setResponseType('blob');
        loader.crossOrigin = '';

        loader.load(
            imageUrl,
            imageBlob => {
                const urlCreator = window.URL || window.webkitURL;
                const url = urlCreator.createObjectURL(imageBlob);
                if (isPanorama) {
                    showImage(isPanorama, url);
                } else {
                    detectPanoramaImage(isPanorama => {
                        showImage(isPanorama, url);
                    })(imageBlob);
                }
            },
            () => {}
        );
    };

    const showImage = (isPanorama, imgUrl) => {
        if (isPanorama && store.file.file.fileType.type === FileTypes.image) {
            qrCodeAction(FileTypes.panorama, store.file);
        }
        setImageUrl(imgUrl);
        setIsPanorama(isPanorama);
    };

    const renderViewer = () => {
        const [Viewer, Controller] = isPanorama
            ? [PanoramaViewer, PanoramaController]
            : [ImageViewer, ImageController];

        return (
            <React.Fragment>
                <Viewer ref={viewer} store={store} sourceUrl={imageUrl} {...rest}>{ children }</Viewer>
                {
                    viewer?.current &&
                    <Controller store={store} viewer={viewer.current} {...rest} />
                }
            </React.Fragment>
        );
    };

    return isPanorama === 'unknown'
        ? <div className='fileview-component-loader' data-what='file-viewer'>{ children }</div>
        : renderViewer();
});

export default observer(ImageLoader);

ImageLoader.propTypes = {
    ...commonPropTypes
};
