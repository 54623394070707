import { observable, action, reaction, makeObservable } from 'mobx';

import { queryString, utils } from '../lib';
import storage from './storage';

const DEFAULT_OPTIONS = {
    sections: {
        history: true,
        comments: true
    },
    closeEnabled: true
};

class UIStore {
    visible = false;
    options = DEFAULT_OPTIONS;

    history = storage.get('preferences.file-view.sections.history.visible');
    comments = storage.get('preferences.file-view.sections.comments.visible');
    overlay = [];

    fullScreen = false;

    constructor (root) {
        makeObservable(this, {
            visible: observable,
            options: observable,
            history: observable,
            comments: observable,
            overlay: observable,
            fullScreen: observable,
            configure: action,
            show: action,
            hide: action
        });

        const search = queryString.parse(window.location.search);
        if (search['file.options.comments.show'] || search['comments.show']) {
            this.comments = true;
        }

        this.root = root;

        this.controller = (() => {
            let $controller;
            const timer = utils.createTimer(5000, () => {
                $controller.removeClass('expanded');
            });
            return {
                init (controller) {
                    $controller = $(controller);
                },
                show () {
                    if (Settings.device.isMobile || !$controller) return;
                    timer.reset();
                    $controller.addClass('expanded');
                },
                hide () {
                    if (Settings.device.isMobile || !$controller) return;
                    timer.stop();
                    $controller.removeClass('expanded');
                },
                toggle () {
                    $controller.toggleClass('expanded');
                }
            };
        })();
        reaction(
            () => this.comments,
            comments => {
                storage.set('preferences.file-view.sections.comments.visible', comments);
                comments ? this.overlay.push('comments') : this.overlay.pop();
            }, {
                delay: 250
            }
        );
        reaction(
            () => this.history,
            history => {
                storage.set('preferences.file-view.sections.history.visible', history);
                history ? this.overlay.push('history') : this.overlay.pop();
            }, {
                delay: 250
            }
        );
        this.history && this.overlay.push('history');
        this.comments && this.overlay.push('comments');
    };

    configure = options => {
        this.options = { ...this.options, ...options };
    };

    show = () => {
        this.visible = true;
        this.root.endLoading();
    };

    hide = () => {
        this.visible = false;
        this.root.endLoading();
    };
}

export default UIStore;
