import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

class VersionsHeader extends React.Component {
    restoreVersion = () =>
        this.props.store.file.file.actions.restore.action([this.props.store.file.currentVersion], 'versions')
            .then(newVersions => {
                runInAction(() => {
                    const newVersion = newVersions.pop();
                    if (this.props.store.file.latestVersion.versionId === newVersion.versionId) return;
                    newVersion.data.previous_versions = [this.props.store.file.file.parent.data].concat(this.props.store.file.file.parent.data.previous_versions);

                    this.props.store.file.previousVersions =
                        this.props.store.file.versions
                            .forEach(v => { v.parent = newVersion; });
                    this.props.store.file.file.parent = newVersion;
                    this.props.store.file.file = newVersion;
                });
            });

    deleteVersion = () =>
        this.props.store.file.file.actions.deleteVersion.action([this.props.store.file.currentVersion])
            .then(() => {
                runInAction(() => {
                    this.props.store.file.file.parent.data.previous_versions =
                        this.props.store.file.file.parent.data.previous_versions
                            .filter(v => v.version_id !== this.props.store.file.currentVersion.versionId);
                    this.props.store.file.file = this.props.store.file.latestVersion;
                });
                window.history.replaceState(null, this.props.store.file.latestVersion.name, this.props.store.file.fileResource.withVersion());
            });

    canExecuteVersionActions = () =>
        this.props.store.file.latestVersion &&
        (this.props.store.file.currentVersion.versionId !== this.props.store.file.latestVersion.versionId);

    renderContextActions = () =>
        this.canExecuteVersionActions() &&
            (<div className='version-context-actions'>
                {
                    this.props.store.file.file.actions.restore &&
                    <span
                        className='tool icon-restore-16'
                        title={gettext('Restore')}
                        onClick={this.restoreVersion}
                        ga-action='File_View'
                        ga-label='Click_Restore'
                    />
                }

                {
                    this.props.store.file.file.actions.deleteVersion &&
                    <span
                        className='tool icon-remove-content-16'
                        title={gettext('Delete')}
                        onClick={this.deleteVersion}
                        ga-action='File_View'
                        ga-label='Click_Delete_Version'
                    />
                }
            </div>);

    render () {
        return (
            <div className='versions-header' data-what='versions-header'>
                { `${gettext('History')} (${this.props.store.file.versions.length})` }
                { this.renderContextActions() }
            </div>
        );
    }
}

export default observer(VersionsHeader);

VersionsHeader.propTypes = {
    store: PropTypes.object.isRequired
};
