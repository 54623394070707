import { observable, computed, reaction, action, makeObservable, runInAction } from 'mobx';

import { queryString } from '../lib';
import { FileTypes } from '../lib/file';
import keyboard from '../base/keyboard';
import { Asset } from '../asset/asset';
import {
    createEmptyFile,
    createNotFoundFile,
    createIntegrationRevokedFile,
    FileGroupIterator,
    fileResourceId
} from './file';
import CommentsStore from './Sections/Comments/store';
import VCDOCCommentsStore from './Sections/Comments/vcdoc-store';
import { showInNomadAction } from '../nomad/ShowInNomad';
import { qrCodeAction } from '../share/qr-code-action';
import { vrCodeAction } from '../share/vr-code-action';
import { actions } from '../asset/actions';

const PREVIEW_PREDICATES = {
    integration: file => !file.integrationRevoked,
    found: file => !file.isEmpty && (file.exists || file.versionId),
    permission: file => file.hasPermission('download') || (file.hasPermission('view') && file.fileType.type === FileTypes.vgx),
    preview: file => (
        file.isFile && file.size > 0 && file.isViewable()
    )
};

class FileStore {
    fileResource;
    commentsStore;
    file = createEmptyFile();
    group = FileGroupIterator.empty();
    previousVersions = [];
    get numberOfFiles () {
        return this.group.items.length;
    }

    get versions () {
        if (this.file.isEmpty || this.preview.error === 'found') {
            return [];
        }

        return (this.file.parent)
            ? this.file.parent.allVersions({ assetActions: actions })
            : [this.file].concat([...this.previousVersions]);
    }

    get currentVersion () {
        return this.file;
    }

    get latestVersion () {
        return this.file.parent ? this.file.parent : this.file;
    }

    get sharedWith () {
        return this.file.sharingInfo.sharedWith.map(u => {
            return {
                login: u.login,
                name: u.username,
                email: u.email
            };
        });
    }

    get isOwner () {
        return Settings.user.login === this.file.owner;
    }

    get hasLink () {
        return !!this.file.sharingInfo.link;
    };

    get preview () {
        const error = Object.keys(PREVIEW_PREDICATES)
            .reduce((error, name) => (
                error || !PREVIEW_PREDICATES[name](this.file) && { error: name }
            ), null);
        return this.file.isEmpty
            ? { ok: true }
            : error || { ok: true };
    };

    get sourceUrl () {
        if (this.preview.error || !this.file.downloadUrl) {
            return '';
        }
        return queryString.buildUrl(this.file.downloadUrl, {
            viewable: 'on',
            embedded: 'on',
            view_content: 'on',
            ...this.queryParams
        });
    }

    constructor (root) {
        makeObservable(this, {
            fileResource: observable,
            file: observable,
            group: observable,
            previousVersions: observable,
            numberOfFiles: computed,
            versions: computed,
            currentVersion: computed,
            latestVersion: computed,
            sharedWith: computed,
            isOwner: computed,
            hasLink: computed,
            preview: computed,
            sourceUrl: computed,
            open: action,
            prev: action,
            next: action,
            empty: action,
            setNotFoundFile: action,
            loadFromGroup: action,
            cleanGroupAndHide: action,
            clearFile: action,
            updateFile: action,
            commentsStore: observable
        });

        this.root = root;
        this.defaultCommentsStore = new CommentsStore(this);
        this.vcdocCommentsStore = new VCDOCCommentsStore(this);
        this.commentsStore = this.defaultCommentsStore;

        reaction(
            () => this.file,
            file => {
                if (file.isEmpty) return;
                const isVCDOC = file.fileType.type === FileTypes.vcdoc;
                if (isVCDOC !== this.isVCDOC) {
                    this.isVCDOC = isVCDOC;
                    this.commentsStore.dispose();
                    this.commentsStore = this.isVCDOC
                        ? this.vcdocCommentsStore
                        : this.defaultCommentsStore;
                    this.commentsStore.bindReactions();
                }
            }
        );

        reaction(
            () => this.file,
            file => {
                (file.isEmpty && !file.name && !this.root.loading)
                    ? this.cleanGroupAndHide()
                    : this.loadNextAndPrevFiles();
                this.previousVersions = file.parent
                    ? file.parent.previousVersions({ assetActions: actions })
                    : file.previousVersions({ assetActions: actions });
                this.root.startLoading();
            }
        );

        reaction(
            () => this.root.ui.visible,
            visible => {
                if (visible) {
                    keyboard.pushKeyEvents({
                        left: {
                            predicate: event => event.which === 37,
                            handler: () => this.prev()
                        },
                        right: {
                            predicate: event => event.which === 39,
                            handler: () => this.next()
                        },
                        delete: {
                            predicate: event => event.which === 46,
                            handler: () => {
                                this.file.hasAction('delete') && this.file.actions.delete.action(this.file, 'fileview');
                            }
                        }
                    });
                } else {
                    keyboard.popKeyEvents();
                }
            }
        );

        this.queryParams = queryString.parse(window.location.search);
    }

    open = fileResource => {
        this.root.ui.show();
        this.fileResource = fileResource;
        this.file = createEmptyFile(fileResource.name);

        this.root.startLoading();
        const fileRequest = this.fileResource.load();

        return fileRequest
            .then(file => {
                const fileType = Asset.guessFile(file).fileType.type;

                const asset = Asset.guessFile(file, {
                    assetActions: actions,
                    extraActions: {
                        showInNomad: showInNomadAction(fileType),
                        qrCode: qrCodeAction(fileType, file),
                        ...(
                            fileType === FileTypes.vgx && Settings.NEW_FEATURES.isActive('vr-codes')
                                ? { vrCode: vrCodeAction(fileType, file) }
                                : {}
                        )
                    }
                });
                const search = this.queryParams;
                if (search['file.versionId']) {
                    runInAction(() => {
                        this.file = asset.allVersions({ assetActions: actions }).find(v => v.versionId === search['file.versionId']);
                    });
                } else {
                    runInAction(() => {
                        this.file = asset;
                    });
                }

                this.group.cache(file);
                this.group.cacheRequest(asset, fileRequest);
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 404) {
                    if (err.response.data.detail === 'Owner credentials expired or revoked.') {
                        this.setIntegrationRevoked();
                    } else {
                        this.setNotFoundFile();
                    }
                }
                this.root.endLoading();
            })
            .finally(() => {
                this.root.endLoading();
            });
    };

    setNotFoundFile = () => {
        const mockFile = createNotFoundFile(this.file.name);
        this.file = mockFile;
    };

    setIntegrationRevoked = () => {
        const mockFile = createIntegrationRevokedFile(this.file.name);
        this.file = mockFile;
    };

    prev = () => this.group.hasPrev() && this.loadFromGroup(this.group.prev());

    next = () => this.group.hasNext() && this.loadFromGroup(this.group.next());

    empty = resource => {
        this.file = createEmptyFile(resource);
    };

    loadFromGroup = item => {
        this.root.startLoading();
        this.fileResource = fileResourceId.fromAsset(item.asset);
        window.history.replaceState(null, item.asset.name, this.fileResource.asUrl());
        if (item.file) {
            this.file = Asset.guessFile(item.file, { assetActions: actions });
            this.root.endLoading();
        } else {
            item.request.then(file => {
                this.file = Asset.guessFile(file, { assetActions: actions });
            });
        }
    };

    loadNextAndPrevFiles = () => {
        if (this.group.items.length > 2) {
            this.group.hasPrev() && !this.group.previewPrev().request && !this.group.previewPrev().file && this.preloadFile(this.group.previewPrev().asset);
            this.group.hasNext() && !this.group.previewNext().request && !this.group.previewNext().file && this.preloadFile(this.group.previewNext().asset);
        } else {
            this.group.hasNext() && !this.group.previewNext().request && !this.group.previewNext().file && this.preloadFile(this.group.previewNext().asset);
        }
    };

    preloadFile = asset => {
        const fileResource = fileResourceId.fromAsset(asset);
        const preloadRequest = fileResource.load();
        this.group.cacheRequest(asset, preloadRequest);
        preloadRequest.then(file => this.group.cache(file));
    };

    cleanGroupAndHide = () => {
        this.root.ui.hide();
        this.group = FileGroupIterator.empty();
    };

    clearFile = () => {
        this.file = createEmptyFile();
    };

    updateFile = newFile => {
        this.file = newFile;
    };
}

export default FileStore;
