import { reaction, makeAutoObservable } from 'mobx';

import storage from '../../../storage';

import {
    TABLES,
    COLUMNS,
    FILTER_TYPES,
    FILTER_COMBINATIONS,
    buildQuery,
    createCollapsableTable
} from './utils';
import { UnitsStore } from './units';
import RecordFactory from './record-factory';

const hiddenColumns = ['id', 'deviceID', 'makeModel'];
const hideEmptyColumns = ['room', 'rackLocation']; // do not display these columns in mobile if their contents are empty

class Column {
    active = true;
    sort = false;

    constructor (id, type, store) {
        this.id = id;
        this.type = type;
        this.store = store;
        this.title = COLUMNS[id]?.title;

        const table = TABLES[store.viewerStore.table];
        this.isCollapsable = store.hasNestedColumns
            ? table.nested.children.map(c => c.id).includes(this.id)
            : table.collapsable.map(c => c.id).includes(this.id);

        makeAutoObservable(this);

        reaction(() => this.active, this.store.viewerStore.query);
        reaction(() => this.sort, this.store.viewerStore.query);
        reaction(() => this.isCollapsable, this.store.viewerStore.query);
    }

    get unit () {
        return this.store.viewerStore.unitsStore.index[this.id]?.unit;
    }

    setActive (v) {
        this.active = v;
    };

    toggleActive = () => {
        this.store.filterBy === this.id &&
            this.store.clearFilter();
        this.setActive(!this.active);
    };

    toggleCollapsable = () => {
        this.isCollapsable = !this.isCollapsable;
    };

    setSort (c) {
        this.sort = c;
    };

    toggleSort = () => {
        this.setSort(
            this.sort
                ? (this.sort === 'ASC' ? 'DESC' : false)
                : 'ASC'
        );
    };

    shouldRender = contents =>
        !(hideEmptyColumns.includes(this.id) && !contents.replaceAll(' ', ''));
}

class ColumnsStore {
    constructor (columns, viewerStore) {
        this.columns = columns;
        this.viewerStore = viewerStore;
        makeAutoObservable(this);
    }

    setColumns = clmns => {
        this.columns = clmns;
    };

    get active () {
        return this.columns.filter(c => !hiddenColumns.includes(c.id) && c.active);
    }

    get collapsable () {
        return this.columns.filter(c => c.isCollapsable);
    }

    get userCollapsable () {
        return this.collapsable.filter(c =>
            !TABLES[this.viewerStore.table].collapsable
                .map(col => col.id)
                .includes(c.id)
        );
    }

    clearCollapsableColumns = () => {
        this.userCollapsable.forEach(c => c.toggleCollapsable());
    };

    get nestedColumn () {
        return this.hasNestedColumns
            ? TABLES[this.viewerStore.table].nested
            : {};
    }

    get hasNestedColumns () {
        return TABLES[this.viewerStore.table].nested;
    }

    get nonCollapsable () {
        return this.active.filter(c => !c.isCollapsable);
    }

    get filterable () {
        return this.columns.filter(c =>
            !hiddenColumns.includes(c.id) &&
            !this.collapsable.map(cC => cC.id).includes(c.id)
        );
    }

    get isEmpty () {
        return this.columns.length === 0;
    }

    get sorting () {
        return this.active.filter(c => c.sort);
    }

    get map () {
        return new Map(this.columns.map(i => [i.id, i]));
    }

    orderCriteria = () => {
        const nonOrderedCollapsables = this.collapsable.filter(cC =>
            !this.sorting.map(c => c.id).includes(cC.id)
        );
        const clauses = [
            ...nonOrderedCollapsables.map(cC => ({ id: cC.id, sort: 'ASC' })),
            ...this.sorting
        ].map(c => `${c.id} ${c.sort}`);
        return clauses.length ? ` ORDER BY ${clauses.join(', ')}` : '';
    };

    searchCriteria = () => this.viewerStore.searchQuery
        ? `(${
            this.active.map(c => `${c.id} LIKE "%${this.viewerStore.searchQuery}%"`).join(' OR ')
        })`
        : '';

    setSortCriterias = () => {
        /*
            Make sure that data is always sorted
            by certain columns,
            e.g. by Device in the Devices view
        */
        this.columns.map(c =>
            this.collapsable
                .map(cC =>
                    (cC === c.id && !c.sort) && c.setSort('ASC')
                )
        );
    };

    setDefaultOrder = () => {
        const tableColumns = TABLES[this.viewerStore.table].columns.slice();
        tableColumns.reverse().forEach(tableColumn => {
            const col = this.columns.find(c => c.id === tableColumn.id);
            this.orderColumnAtPosition(col, 0);
        });
    };

    orderColumnAtPosition = (column, position) => {
        /*
            Reorder the columns so that
            <column> is in <position>
        */
        const oldPosition = this.columns.findIndex(c => c.id === column.id);
        const newColumns = [...this.columns];
        newColumns.splice(oldPosition, 1);
        newColumns.splice(position, 0, column);

        this.columns = newColumns;
    };
}

class NavigationHistory {
    constructor (viewerStore) {
        this.navigationHistory = [];
        this.store = viewerStore;
    }

    add = ({ table, sourceItem = null, scrollPosition = null }) => {
        this.navigationHistory.push({ table, sourceItem, scrollPosition });
    };

    goBack = () => {
        return this.navigationHistory.length > 0
            ? this.navigationHistory.pop()
            : {
                table: null,
                sourceItem: null,
                scrollPosition: null
            };
    };
}

class CCADViewerStore {
    constructor () {
        this.db = null;
        this.table = storage.get('ccad.table', TABLES.circuits.name);

        this.navigationHistory = new NavigationHistory(this);
        this.columns = new ColumnsStore([], this);
        this.unitsStore = new UnitsStore();
        this.records = [];
        this.filters = {};
        this.filterCombination = storage.get('ccad.filter.combination') || FILTER_COMBINATIONS.AND;
        this.searchQuery = null;

        makeAutoObservable(this);

        reaction(() => this.table, this.loadTable);
        reaction(() => this.filters, this.query);
        reaction(() => this.searchQuery, this.query);
        reaction(() => this.columns.collapsable.length, this.query);
    }

    setTable = tbl => {
        this.table = tbl;
        storage.set('ccad.table', tbl);
    };

    loadTable = () => {
        this.columns.setColumns([]);
        this.records = [];
        this.isQueryEmpty = false;
        this.filters = {};
        this.searchQuery = null;

        this.query();
    };

    setFilterCombination = combination => {
        this.filterCombination = combination;
        storage.set('ccad.filter.combination', combination);
        this.query();
    };

    setFilter = (columnId, filter) => {
        this.filters[columnId] = {
            ...this.filters[columnId],
            filter,
            isActive: true
        };
        this.query();
    };

    setFilterType = (columnId, type) => {
        this.filters[columnId] = {
            ...this.filters[columnId],
            type,
            isActive: true
        };
        this.query();
    };

    setFilterValue = (columnId, value) => {
        this.filters[columnId] = {
            ...this.filters[columnId],
            value,
            isActive: true
        };
        this.query();
    };

    setFilterFrom = (columnId, from) => {
        this.filters[columnId] = {
            ...this.filters[columnId],
            from,
            isActive: true
        };
        this.query();
    };

    setFilterTo = (columnId, to) => {
        this.filters[columnId] = {
            ...this.filters[columnId],
            to,
            isActive: true
        };
        this.query();
    };

    unsetFilter = columnId => {
        this.filters[columnId] = {};
        this.query();
    };

    setSearchQuery = v => {
        this.searchQuery = v;
    };

    clearFilters = () => {
        this.filters = {};
        this.query();
    };

    toggleFilter = columnId => {
        this.filters[columnId].isActive = !this.filters[columnId].isActive;
        this.query();
    };

    isFilterSet = filter => {
        // assert the necessary fields for the filter have been set
        const filterType = Object.values(FILTER_TYPES).find(ft => ft.name === filter.type);
        return filterType &&
            filterType.requiredFields.every(field => filter[field] && filter[field].length > 0);
    };

    buildFilterQuery = skipFilters => {
        const query = Object.keys(this.filters)
            .filter(columnId => {
                const condition = this.isFilterSet(this.filters[columnId]) &&
                    this.filters[columnId].isActive;
                return skipFilters?.length > 0
                    ? condition && !skipFilters.includes(columnId)
                    : condition;
            })
            .map(columnId => {
                const filter = this.filters[columnId];
                const filterType = Object.values(FILTER_TYPES).find(ft => ft.name === filter.type);
                const { condition, requiredFields } = filterType;
                const column = Object.values(COLUMNS).find(c => c.id === columnId);
                const convertedColumn = this.unitsStore.index[columnId]
                    ? this.unitsStore.convertColumn(column)
                    : COLUMNS[columnId].query;
                return filter.filter[condition](
                    convertedColumn,
                    ...requiredFields.map(field => filter[field])
                );
            })
            .filter(c => c.length > 0)
            .join(` ${this.filterCombination.query} `);

        return query
            ? `(${query})`
            : '';
    };

    buildBaseQuery = table => {
        const columnQueries = table.columns.map(c => this.unitsStore.index[c.id]
            ? `${this.unitsStore.convertColumn(c)} as ${c.id}`
            : `${c.query} as ${c.id}`
        );
        return `SELECT ${columnQueries.join(', ')} FROM ${table.view}`;
    };

    query = () => {
        const baseQuery = this.buildBaseQuery(TABLES[this.table]);
        const query = buildQuery(baseQuery, this);
        const [results] = this.db?.exec(query);
        if (results) {
            const { columns, values } = results;
            if (this.columns.isEmpty) {
                this.columns.setColumns(
                    columns.map(id =>
                        new Column(id, COLUMNS[id]?.type, this.columns)
                    )
                );
            }
            const records = values.map(v => v.reduce((acc, c, i) => ({ ...acc, [columns[i]]: c }), {}));
            const uniqueRecords = records.reduce((acc, c) => acc.find(item => item.id === c.id)
                ? acc
                : [...acc, c], []);
            this.records = uniqueRecords.map(r => RecordFactory.create(this.table, r));
            this.columns.setSortCriterias();
            this.columns.setDefaultOrder();
            if (this.columns.collapsable.length) {
                this.records = createCollapsableTable(this.records, this.columns.collapsable.map(c => c.id));
                // reorder the columns so that the collapsable ones are always first
                this.columns.collapsable
                    .slice()
                    .reverse()
                    .forEach(col => this.columns.orderColumnAtPosition(col, 0));
            }
            this.isQueryEmpty = false;
        } else {
            this.records = [];
            this.isQueryEmpty = true;
        }
    };
}

export default CCADViewerStore;
