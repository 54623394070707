import React from 'react';

import Icon from '../../Components/Icon';

const NotFound = () => (
    <div className='no-preview'>
        <div className='message-box flex-col'>
            <Icon icon='icon-view'/>
            <div className='message'>
                <span className='msg-row-prim'>{ gettext('File not found') }</span>
                <span>{ gettext('Sorry, the file no longer exists.') }</span>
            </div>
        </div>
    </div>);

export default NotFound;
