import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import VersionsList from './VersionsList';
import VersionsHeader from './VersionsHeader';

class History extends Component {
    constructor (props) {
        super(props);
        this.panelRef = React.createRef();
    }

    getZIndex = () => {
        return this.props.store.ui.overlay.length > 1 &&
            this.props.store.ui.overlay[1] === 'history' &&
            this.panelRef.current &&
            Number(window.getComputedStyle(this.panelRef.current).zIndex) + 1;
    };

    render () {
        return (
            <div
                className={`versions-history ${this.props.store.ui.history ? 'expanded' : ''}`}
                ref={this.panelRef}
                style={{ zIndex: this.getZIndex() }}
            >
                <VersionsHeader store={this.props.store}/>
                {
                    Settings.NEW_FEATURES.isActive('previous-versions-maintenance') &&
                    <div className='message-bubble'>
                        { gettext('Older file versions can be restored for up to 90 days after they are created.') }
                    </div>
                }
                <VersionsList store={this.props.store}/>
            </div>
        );
    }
}

export default observer(History);

History.propTypes = {
    store: PropTypes.object.isRequired
};
