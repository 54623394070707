import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { pubsub } from '../../../../base';
import storage from '../../../storage';
import splitContextActions from '../../../../base/toolbar-context-actions';
import ToolbarContextActions, { renderListAction } from '../../../../Components/ToolbarContextActions';
import withResponsiveListeners from '../../../../utils/Responsive';

import { TABLES } from './utils';
import Toolbar from '../../../Toolbar';
import GlobalTools from '../../../GlobalTools';

const Table = ({ table, activeTable }) => {
    const onClick = () => pubsub.publish('fv.ccad.table.changed', table.name);

    return (
        <a
            className={`tool icon-${table.icon} ${activeTable === table.name ? 'tool-active' : ''}`}
            title={table.title}
            onClick={onClick}
            data-what='fv-action'
            data-which={`table-${table.name}`}
            ga-action={`CCAD_${table.name.toUpperCase()}`}
            ga-label={`Open_CCAD_${table.name.toUpperCase()}`}
        />
    );
};

Table.propTypes = {
    table: PropTypes.object,
    activeTable: PropTypes.string
};

const Units = () => {
    const onClick = () => {
        pubsub.publish('fv.ccad.units.dialog.open');
    };

    return (
        <a
            className='tool icon-settings'
            title={ gettext('Set units') }
            onClick={onClick}
            data-what='fv-action'
            data-which='ccad-units'
            ga-action='CCAD_Units'
            ga-label='Open_CCAD_Units'
        />
    );
};

const Tables = ({ active }) => {
    return (
        <React.Fragment>
            {
                Object.keys(TABLES).map(t =>
                    <Table
                        key={t}
                        table={TABLES[t]}
                        activeTable={active}
                    />
                )
            }
        </React.Fragment>
    );
};

Tables.propTypes = {
    active: PropTypes.string
};

const ConnectCADToolbar = observer(({ store, screen }) => {
    const fileStore = store.file;
    const { baseName: fileName, actions } = fileStore.file;
    const [table, setTable] = React.useState(
        storage.get('ccad.table', TABLES.circuits.name)
    );
    const isMobile = React.useMemo(() => !['md', 'lg'].includes(screen.size), [screen]);

    React.useEffect(() => {
        pubsub.subscribe(ConnectCADToolbar, 'fv.ccad.table.changed', changeTable);

        return () => {
            pubsub.unsubscribeAll(ConnectCADToolbar);
        };
    }, []);

    const doAction = (action, context = null) => () => {
        return actions[action].action.call(context, fileStore.file);
    };

    const changeTable = table => {
        setTable(table);
    };

    return (
        <div className='toolbar fv-toolbar ccad-fv-toolbar'>
            {
                isMobile
                    ? <div
                        className='dropdown context-tools'
                        data-what='toolbar-dropdown'
                    >
                        <a className='dropdown-toggle' data-toggle='dropdown'>
                            <span className='tool icon-more'/>
                        </a>

                        <ul className='dropdown-menu override-bootstrap-dropdown'>
                            {
                                splitContextActions(actions, 3, ['view'])
                                    .primary
                                    .map(action => renderListAction(doAction, action))
                            }
                        </ul>
                        <React.Fragment>
                            <Tables active={table} />
                            <Units />
                        </React.Fragment>
                    </div>
                    : <Toolbar.FileName>
                        {`${fileName}: ${TABLES[table].title}`}
                    </Toolbar.FileName>
            }
            {
                !isMobile &&
                    <div className='toolbar-middle-panel'>
                        <React.Fragment>
                            <Tables active={table} />
                            <Units />
                        </React.Fragment>
                    </div>
            }

            <div className='toolbar-right-panel'>
                <GlobalTools store={store}/>
                <Toolbar.ToolSeparator />
                {
                    !isMobile &&
                        <ToolbarContextActions
                            items={[fileStore.file]}
                            actions={actions}
                            hiddenActions={['view', 'restore', 'deleteVersion']}
                        />
                }
            </div>
        </div>
    );
});

ConnectCADToolbar.propTypes = {
    store: PropTypes.object
};

export default withResponsiveListeners(ConnectCADToolbar);
