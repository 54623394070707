import React, { useRef } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import assetApi from '../../asset/api';

import { DefaultController } from './Controllers/Default';
import DefaultViewer from './DefaultViewer';
import EmptyViewer from './EmptyViewer';
import ImageController from './Controllers/ImageController';
import ImageLoader from './ImageLoader';
import VideoLoader from './VideoLoader';
import ImageViewer from './ImageViewer';
import PDFController from './Controllers/PDFController';
import PDFViewer from './PDFViewer';
import VCDOCViewer from './VCDOCViewer';
import VGXLoader from './VGXLoader';
import PanoramicVideoViewer from './PanoramicVideoViewer';
import VWXViewer from './VWXViewer';
import ConnectCADViewer from './ConnectCADViewer/index';
import Spinner from '../../Components/Spinner';

const viewers = {
    image: {
        viewer: ImageViewer,
        controller: ImageController
    },
    pdf: {
        viewer: PDFViewer,
        controller: PDFController
    },
    vcdoc: {
        viewer: VCDOCViewer,
        controller: () => null
    },
    vwx: {
        viewer: VWXViewer,
        controller: DefaultController
    },
    video: {
        viewer: VideoLoader,
        controller: () => null
    },
    video_360: {
        viewer: PanoramicVideoViewer,
        controller: () => null
    },
    panorama: {
        viewer: ImageLoader,
        controller: () => null
    },
    vgx: {
        viewer: VGXLoader,
        controller: () => null,
        controllerTogglers: store => ({
            onMouseMove: Settings.device.isMobile ? null : store.ui.controller.show,
            onMouseLeave: Settings.device.isMobile ? null : store.ui.controller.hide
        })
    },
    heic: {
        viewer: ImageViewer,
        controller: ImageController
    },
    connect_cad: {
        viewer: ConnectCADViewer,
        controller: () => null
    },
    default: {
        viewer: DefaultViewer,
        controller: DefaultController,
        controllerTogglers: store => ({
            onMouseMove: null,
            onMouseLeave: null
        })
    },
    empty: {
        viewer: EmptyViewer,
        controller: DefaultController,
        controllerTogglers: store => ({
            onClick: null
        })
    }
};

const ViewWrapper = observer((props) => {
    const viewerRef = useRef(null);
    const Controller = props.controller;
    const Viewer = props.viewer;

    return (
        <React.Fragment>
            <Viewer
                store={props.store}
                controllerTogglers={props.controllerTogglers}
                ref={viewerRef}
                {...props.additionalArgs}
            >
                { props.store.loading && <Spinner /> }
            </Viewer>
            {
                viewerRef?.current &&
                <Controller store={props.store} viewer={viewerRef.current}/>
            }
        </React.Fragment>
    );
});

ViewWrapper.propTypes = {
    store: PropTypes.object,
    controllerTogglers: PropTypes.object,
    controller: PropTypes.any,
    viewer: PropTypes.any,
    additionalArgs: PropTypes.any
};

const registerLinkVisit = function (file) {
    if (window.location.pathname.startsWith('/links') && file.file.ownerInfo.link) {
        if (file.preview.error === 'preview' || file.file.fileType.type === 'vwx') {
            file.file.ownerInfo.link.visitCountOnDownload = true;
            return;
        }
        assetApi.linkVisit(file.file.ownerInfo.link.uuid);
    }
};

const renderViewer = ({ store, type, additionalArgs = {} }) => {
    const viewerConfig = viewers[type] ? viewers[type] : viewers.default;

    const controllerTogglers = {
        onMouseMove: store.ui.controller.show,
        onClick: Settings.device.isMobile ? store.ui.controller.toggle : null,
        ...(
            viewerConfig.controllerTogglers
                ? viewerConfig.controllerTogglers(store)
                : {}
        )
    };

    registerLinkVisit(store.file);

    return (
        <ViewWrapper
            store={store}
            controllerTogglers={controllerTogglers}
            viewer={viewerConfig.viewer}
            controller={viewerConfig.controller}
            additionalArgs={additionalArgs}
        />
    );
};

renderViewer.propTypes = {
    store: PropTypes.object,
    type: PropTypes.string,
    additionalArgs: PropTypes.any
};

export default renderViewer;
