import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, makeObservable } from 'mobx';

import api from '../../../asset/api';

class FileVersion extends Component {
    constructor (props) {
        super(props);

        makeObservable(this, {
            openVersion: action
        });

        this.state = {
            editing: false,
            isPending: props.version.isPendingVersion,
            error: '',
            label: props.version.label || '',
            editableLabel: props.version.label || ''
        };
    }

    componentDidMount () {
        if (this.labelField) {
            const label = this.state.label;
            this.labelField.focus();
            this.labelField.value = '';
            this.labelField.value = label;
        }
    };

    openVersion = version => {
        const { store } = this.props;
        const { file } = store;
        if (file.currentVersion.versionId === version.versionId) {
            return;
        }

        if (!Settings.openedFromPublicLink) {
            const resource = file.latestVersion.versionId === version.versionId
                ? file.fileResource.withVersion()
                : file.fileResource.withVersion('', version.versionId);
            window.history.replaceState(null, version.name, resource);
        }
        this.props.store.file.file = version;
    };

    isSelectedClass = () =>
        this.props.store.file.currentVersion.versionId === this.props.version.versionId
            ? 'item-selected-light selected-borders'
            : '';

    handleLabelChange = e => {
        this.setState({ editableLabel: e.target.value });
        e.target.value.length > 255
            ? this.setState({ error: gettext('The maximum length is 255 characters.') })
            : this.setState({ error: '' });
    };

    toggleEditing = e => {
        e.stopPropagation();
        this.setState({ editing: !this.state.editing });
        this.state.editing && this.setState({ editableLabel: this.state.label });
    };

    finishEditing = e => {
        e.stopPropagation();
        if (!this.state.error) {
            this.setState({ editing: !this.state.editing });
            api.updateVersionLabel(this.props.version, this.state.editableLabel)
                .then(() => this.setState({ label: this.state.editableLabel }));
        }
    };

    stopPropagation = e => {
        e.stopPropagation();
    };

    checkForKeyAction = e => {
        if (!e.shiftKey && e.keyCode === 13) {
            e.preventDefault();
            this.finishEditing(e);
        }
        if (e.keyCode === 27) {
            this.setState({ editing: !this.state.editing });
        }
    };

    renderButtons = () =>
        (<div className='edit-column'>
            {
                this.state.editing
                    ? <span
                        className='icon icon-check-16'
                        title={gettext('Finish')}
                        onClick={this.finishEditing}
                        ga-action='File_View'
                        ga-label='History_Element_View_Edit_Update_Label'
                        data-what='submit-label'
                    />
                    : <span
                        className='icon icon-edit-16'
                        title={gettext('Edit label')}
                        onClick={this.toggleEditing}
                        ga-action='File_View'
                        ga-label='History_Element_View_Edit_Label'
                        data-what='edit-label'
                    />
            }
        </div>);

    renderErrorMessage = () =>
        (<div
            className='validation-block'
            onClick={e => e.stopPropagation()}
        >
            {
                this.state.error &&
                    <span className='di-input-text di-input-error'>
                        { this.state.error }
                    </span>
            }
        </div>);

    render () {
        const canEditLabels = this.props.store.file.isOwner ||
            this.props.store.file.file.ownerInfo.hasPermission('modify');

        return this.state.isPending
            ? <div
                className={`file-version ${this.isSelectedClass()}`}
                key={`${this.props.version.versionId}-${this.props.version.lastModified}-${this.props.version.size}`}
                onClick={this.openVersion.bind(this, this.props.version)} // eslint-disable-line
                ga-action='File_View'
                ga-label='History_Element_View'
                data-what='file-version'
                data-which={this.props.version.versionId}
            >
                <div className='version-info pending'>
                    <div className='lds lds--small lds--grey'/>
                    <div className='desc'>{ gettext('Pending upload...') }</div>
                </div>
            </div>
            : <div
                className={`file-version ${this.isSelectedClass()}`}
                key={`${this.props.version.versionId}-${this.props.version.lastModified}-${this.props.version.size}`}
                onClick={this.openVersion.bind(this, this.props.version)} // eslint-disable-line
                ga-action='File_View'
                ga-label='History_Element_View'
                data-what='file-version'
                data-which={this.props.version.versionId}
            >
                <div className='version-info'>
                    <div className='last-mod'>
                        <span className='prim'>{ this.props.version.dateModified }</span>
                        <span className='prim size'>{ this.props.version.sizeText }</span>
                    </div>
                    {
                        this.state.editing
                            ? <textarea
                                className='label-field'
                                value={this.state.editableLabel}
                                onChange={this.handleLabelChange}
                                onClick={this.stopPropagation}
                                onKeyDown={this.checkForKeyAction}
                                type='text'
                                ref={textarea => { this.labelField = textarea; }}
                                data-what='label-field'
                            />
                            : this.state.label && <div className='secondary label'>{ this.state.label }</div>
                    }
                    {
                        this.state.editing && this.renderErrorMessage()
                    }
                </div>
                { canEditLabels && this.renderButtons() }
            </div>;
    }
}

export default FileVersion;

FileVersion.propTypes = {
    store: PropTypes.object.isRequired,
    version: PropTypes.object.isRequired
};
